import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
const token = "d21zVXNlcjpIMWJiM1JUIQ=="

export const redeemCertificate = createAsyncThunk(
    "redeem",
    async (arg, { rejectWithValue }) => {
      try {
        const API_HOST_SEARCH = `${process.env.REACT_APP_API_HOST_URL}${process.env.REACT_APP_order_placement}`;
          const data = await axios.get(
            `${API_HOST_SEARCH}/certificateCheck?certificateId=${arg}&customToken=${token}`,
            {
              headers: {
                'Access-Control-Allow-Credentials': 'true'
              },
            }
          );
          return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  export const placeOrder = createAsyncThunk(
    "placeOrder",
    async (arg, { rejectWithValue }) => {
      try {
        const API_HOST_SEARCH = `${process.env.REACT_APP_API_HOST_URL}${process.env.REACT_APP_order_placement}`;
          const data = await axios.post(
            `${API_HOST_SEARCH}/placeMicroOrderUI?customToken=${token}`,
            arg,
            {
              headers: {
                'Access-Control-Allow-Credentials': 'true'
              },
            },
          );
          return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  
const initialState = {
  language: "en",  
  loading: false,
  certificateId: "",
  certificate: {},
  order: {}
}

export const landingSlice = createSlice({
  name: 'landing',
  initialState,
  reducers: {
    changeLanguage(state) {
        state.language = "es";
      },
  },
  extraReducers: builder => {
    builder.addCase(redeemCertificate.pending,(state) => {
      state.loading = true;
      state.certificate = {};
      state.errMessage = "";
    })
    builder.addCase(redeemCertificate.fulfilled,(state, { payload }) => {
        state.loading = false;
        state.certificate = payload.data;
        state.certificateId = payload.data.responseBody.certificateId;
        state.errMessage = payload.data.errorMessage
      })
      builder.addCase(redeemCertificate.rejected,(state, { payload }) => {
        state.loading = false;
        state.certificateId = "",
        state.errMessage = payload?.message || "Something Went Wrong!"
      })
      builder.addCase(placeOrder.pending,(state) => {
        state.loading = true;
        state.errorMessage = "";
      })
      builder.addCase(placeOrder.fulfilled,(state, { payload }) => {
          state.loading = false;
          state.order = payload.data;
          state.errorMessage = payload.data.errorMessage
        })
        builder.addCase(placeOrder.rejected,(state, { payload }) => {
          state.loading = false;
          state.order = {},
          state.errorMessage = "Something Went Wrong!"
        })
}
})

export const { changeLanguage } = landingSlice.actions


export default landingSlice.reducer