import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";
import Footer from "../components/footer";
import Header from "../components/header";
import { useSelector, useDispatch } from "react-redux";
import { placeOrder } from "../slices/landingSlice";

const patientFormSchema = Yup.object().shape({
  physicianName: Yup.string().required(),
  practiceName: Yup.string().required(),
  practiceCity: Yup.string().required(),
  practiceState: Yup.string().required(),
  shipToFirstName: Yup.string().required(),
  shipToLastName: Yup.string().required(),
  shipToAddress1: Yup.string().required(),
  shipToAddress2: Yup.string(),
  shipToCity: Yup.string().required(),
  shipToState: Yup.string().required(),
  shipToZipCode: Yup.string().required(),
  // shippingInstruction: Yup.string(),
  shipToEmailAddress: Yup.string(),
});

const PatientForm = () => {
  const dispatch = useDispatch();
  const { order, errorMessage, language } = useSelector((state) => state.landing);
  const certificateId = localStorage.getItem('certificateId');
  const [postData, setPostData] = useState({});
  const formik = useFormik({
    initialValues: {
      certificateId,
      companyNumber: "1317",
      physicianName: "",
      practiceName: "",
      practiceCity: "",
      practiceState: "",
      shipToFirstName: "",
      shipToLastName: "",
      shipToAddress1: "",
      shipToAddress2: "",
      shipToCity: "",
      shipToState: "",
      shipToZipCode: "",
      // shippingInstruction: "",
      shipToEmailAddress: "",
    },
    onSubmit: (values) => {
      if (!terms) {
        alert("Please accept Terms and Condition to proceed");
        return;
      }
      formik.validateForm();
      const pData = {
        ...values,
        billToState: values.shipToState,
        billToFirstName: values.shipToFirstName,
        billToLastName: values.shipToLastName,
        billToAddress1: values.shipToAddress1,
        billToAddress2: values.shipToAddress2,
        billToCity: values.shipToCity,
        billToZipCode: values.shipToZipCode,
        language
      };
      setPostData(pData);
      setOpen(true);
    },
    validationSchema: patientFormSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });
  const { handleChange, values, errors, handleBlur, touched } = formik;
  const locale = useIntl();
  const [open, setOpen] = useState(false);
  const [terms, setTerms] = useState(false);
  const navigate = useNavigate();
  function submitData() {
      dispatch(placeOrder(postData));
    setOpen(false);
  }

  const renderErrorMessage = (fieldName, name, errorFieldname) => {
    if (errors[name] && touched[name]) {
      return locale?.locale === "es" ? (
        <>
          <div className="error2">
            <i className="fa fa-exclamation-triangle"></i>{" "}
            {errorFieldname  ? (<FormattedMessage id={errorFieldname} />) : (<><FormattedMessage id="redeemForm.isRequired" />
            <FormattedMessage id={fieldName} /></>)}
          </div>
        </>
      ) : (
        <>
          <div className="error2">
            <i className="fa fa-exclamation-triangle"></i>{" "}
            <FormattedMessage id={fieldName} />
            <FormattedMessage id="redeemForm.isRequired" />
          </div>
        </>
      );
    }
  };
  useEffect(() => {
      if (order?.status === "success") {
        navigate("/confirmation");
      }
  },[order])
  return (
    <>
      <div className="main">
        {open ? (
          <div id="infoModal" className="overlay">
            <div className="popup">
              <div className="body">
                <div className="group">
                  <div className="span12">
                    <div className="overlay-content">
                      {" "}
                      <FormattedMessage id="redeemForm.popup.text" />{" "}
                    </div>
                    <div className="btn-center margin-top1">
                      <button
                        data-testid="submit-data"
                        onClick={() => submitData()}
                        className="btn-primary  sm-btn"
                      >
                        <FormattedMessage id="redeemForm.popup.btn1" />
                      </button>
                      <button
                        className="btn-primary  sm-btn"
                        onClick={() => setOpen(false)}
                      >
                        <FormattedMessage id="redeemForm.popup.btn2" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <Header />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
            // formik.submitForm(e);
          }}
        >
          <div className="container">
            <article className="card3 shadow-1">
              <h1>
                <FormattedMessage id="redeemForm.title" />
              </h1>
              <div className="group">
                <div className="span12">
                  <div className="form-field">
                    <label htmlFor="physicianName">
                      <FormattedMessage id="redeemForm.field1" />:{" "}
                      <span className="red">*</span>
                    </label>
                    <input
                      name="physicianName"
                      autoFocus
                      id="physicianName"
                      value={values.physicianName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=""
                      placeholder=""
                      type="text"
                    />
                    {renderErrorMessage("redeemForm.field1", "physicianName")}
                  </div>
                  <div className="form-field">
                    <label htmlFor="practiceName">
                      <FormattedMessage id="redeemForm.field2" />:{" "}
                      <span className="red">*</span>
                    </label>
                    <input
                      name="practiceName"
                      id="practiceName"
                      value={values.practiceName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=""
                      placeholder=""
                      type="text"
                    />
                    {renderErrorMessage("redeemForm.field2", "practiceName")}
                  </div>
                  <div className="form-field">
                    <label htmlFor="practiceCity">
                      <FormattedMessage id="redeemForm.field3" />:{" "}
                      <span className="red">*</span>
                    </label>
                    <input
                      name="practiceCity"
                      id="practiceCity"
                      value={values.practiceCity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=""
                      placeholder=""
                      type="text"
                    />
                    {renderErrorMessage("redeemForm.field3", "practiceCity", "redeemForm.field3Error")}
                  </div>
                  <div className="form-field">
                    <label htmlFor="practiceState">
                      <FormattedMessage id="redeemForm.field4" />:{" "}
                      <span className="red">*</span>
                    </label>
                    <select
                      name="practiceState"
                      id="practiceState"
                      value={values.practiceState}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">
                        <FormattedMessage id="redeemForm.selectState" />
                      </option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                    {renderErrorMessage("redeemForm.field4", "practiceState", "redeemForm.field4Error")}
                  </div>
                  <br />
                  <h1 className="mtop40">
                    <FormattedMessage id="redeemForm.shipping.title" />
                  </h1>
                  <div className="form-field">
                    <label htmlFor="shipToFirstName">
                      <FormattedMessage id="redeemForm.shipping.field1" />:{" "}
                      <span className="red">*</span>
                    </label>
                    <input
                      name="shipToFirstName"
                      id="shipToFirstName"
                      value={values.shipToFirstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=""
                      placeholder=""
                      type="text"
                    />
                    {renderErrorMessage(
                      "redeemForm.shipping.field1",
                      "shipToFirstName"
                    )}
                  </div>
                  <div className="form-field">
                    <label htmlFor="shipToLastName">
                      <FormattedMessage id="redeemForm.shipping.field2" />:{" "}
                      <span className="red">*</span>
                    </label>
                    <input
                      name="shipToLastName"
                      id="shipToLastName"
                      value={values.shipToLastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=""
                      placeholder=""
                      type="text"
                    />
                    {renderErrorMessage(
                      "redeemForm.shipping.field2",
                      "shipToLastName"
                    )}
                  </div>
                  <div className="form-field">
                    <label htmlFor="shipToAddress1">
                      <FormattedMessage id="redeemForm.shipping.field3" />:{" "}
                      <span className="red">*</span>
                    </label>
                    <input
                      name="shipToAddress1"
                      id="shipToAddress1"
                      value={values.shipToAddress1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=""
                      placeholder=""
                      type="text"
                    />

                    {renderErrorMessage(
                      "redeemForm.shipping.field3",
                      "shipToAddress1",
                      "redeemForm.shipping.field3Error"
                    )}
                  </div>
                  <div className="form-field">
                    <label htmlFor="shipToAddress2">
                      <FormattedMessage id="redeemForm.shipping.field4" />:
                    </label>
                    <input
                      name="shipToAddress2"
                      id="shipToAddress2"
                      value={values.shipToAddress2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=""
                      placeholder=""
                      type="text"
                    />
                  </div>
                  <div className="form-field">
                    <label htmlFor="shipToCity">
                      <FormattedMessage id="redeemForm.shipping.field5" />:{" "}
                      <span className="red">*</span>
                    </label>
                    <input 
                      id="shipToCity"
                      name="shipToCity"
                      data-testid="shipToCity"
                      value={values.shipToCity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=""
                      placeholder=""
                      type="text"
                    />
                    {renderErrorMessage(
                      "redeemForm.shipping.field5",
                      "shipToCity",
                      "redeemForm.shipping.field5Error"
                    )}
                  </div>
                  <div className="form-field">
                    <label htmlFor="shipToState">
                      <FormattedMessage id="redeemForm.shipping.field6" />:{" "}
                      <span className="red">*</span>
                    </label>
                    <select
                      data-testid="shipToState"
                      name="shipToState"
                      value={values.shipToState}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">
                        <FormattedMessage id="redeemForm.selectState" />
                      </option>
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                    {renderErrorMessage(
                      "redeemForm.shipping.field6",
                      "shipToState"
                    )}
                  </div>
                  <div className="form-field">
                    <label htmlFor="shipToZipCode">
                      <FormattedMessage id="redeemForm.shipping.field7" />:{" "}
                      <span className="red">*</span>
                    </label>
                    <input
                      name="shipToZipCode"
                      id="shipToZipCode"
                      value={values.shipToZipCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=""
                      placeholder=""
                      type="text"
                    />
                    {renderErrorMessage(
                      "redeemForm.shipping.field7",
                      "shipToZipCode"
                    )}
                  </div>
                  {/* <div className="form-field">
                    <label>
                      <FormattedMessage id="redeemForm.shipping.field8" />:
                    </label>
                    <textarea
                      name="shippingInstruction"
                      value={values.shippingInstruction}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></textarea>
                  </div> */}
                  <div className="form-field">
                    <label htmlFor="shipToEmailAddress">
                      <FormattedMessage id="redeemForm.shipping.field9" />:
                    </label>
                    <input
                      id="shipToEmailAddress"
                      name="shipToEmailAddress"
                      value={values.shipToEmailAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=""
                      placeholder=""
                      type="text"
                    />
                    <div className="field-tip">
                      <FormattedMessage id="redeemForm.shipping.emailHelper" />
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="sub-form-text">
                    <p>
                      <strong>
                        <input
                          checked={terms}
                          onChange={(ev) => setTerms(ev.target.checked)}
                          type="checkbox"
                        />{" "}
                        <FormattedMessage id="redeemForm.terms.text1" />{" "}
                        <span className="highlight">
                          <FormattedMessage id="redeemForm.terms.text2" />
                        </span>{" "}
                        <FormattedMessage id="redeemForm.terms.text3" />
                      </strong>
                    </p>
                    <p>
                      {" "}
                      <FormattedMessage id="redeemForm.privacy.text1" />{" "}
                      <a target="_blank" href="https://us.eisai.com/privacy-policy">
                        <FormattedMessage id="redeemForm.privacy.text2" />
                      </a>{" "}
                      <FormattedMessage id="redeemForm.privacy.text3" />
                    </p>
                  </div>
                  <button
                    data-testid="submit"
                    type="submit"
                    className="btn-primary right"
                  >
                    <FormattedMessage id="redeemForm.submit" />
                  </button>
                </div>
                {errorMessage ? <div className="error">{errorMessage}</div> : null}
              </div>
            </article>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default PatientForm;
