import React from "react";
import { FormattedMessage } from "react-intl";

const Header = ({ isHomepage }) => {
  return (
    <div className="header">
      <div className="header-logo"></div>
      <div className="header-text">
        {isHomepage ? (
          "Lenvima Welcome kit"
        ) : (
          <FormattedMessage id="header.text" />
        )}
      </div>
    </div>
  );
};

export default Header;
