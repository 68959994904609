export const englishText = {
    header: {
      text: "Certificate Redemption Form",
    },
    footer: {
        text1: "This site is intended for residents of the United States only. The products discussed herein may have different product labeling in different countries.",
        text2: "To report suspected adverse reactions, contact Eisai Inc. at 1-888-274-2378 or the FDA at 1-800-FDA-1088 or",
        text3: "LENVIMA® is a registered trademark used by Eisai Inc. under license from Eisai R&D Management Co. Ltd.",
        text4: "© 2024 Eisai Inc. All rights reserved. July 2024 LENV-US10946",
        link1: "About Eisai",
        link2: "Privacy Policy",
        link3: "Legal Notices"
    },
    redeemCertificate: {
    title: "Certificate Code",
    subtitle: "Enter Certificate Code",
    error: "Invalid Certificate Code",
    submit: "Submit",
    info1: "Contact Sonexus at",
    info2: "Monday - Fri / 8am - 8pm",
    clickHere: "Click Here",
    needHelp: "Need Helping Finding Code",
    footer: "For another way to redeem, see the Lenvima Eisai Patient Support Enrollment Form."
    },
    redeemForm: {
        title: "Please Enter the Name of the Practice Where You Receive the Certificate for the BP Monitor",
        field1 : "Physician Name",
        field2 : "Practice Name",
        field3: "Practice City",
        field4: "Practice State",
        isRequired: " is required",
        selectState: "--Select State--",
        popup: {
            btn1: "Ok",
            btn2: "Cancel",
            text: "Please note in order to redeem the certificate, you must read the terms and conditions and verify that that you qualify. If you do not qualify, please exit the window. Please contact Sonexus at 1-866-613-4724 if any additional questions or concerns."
        },
        shipping: {
            title: "Enter Patient Shipping Information",
            field1 : "First Name",
            field2 : "Last Name",
            field3: "Address 1",
            field4: "Address 2",
            field5: "City",
            field6: "State",
            field7: "Zip",
            field8: "Shipping Instructions",
            field9: "Email Address",
            emailHelper: "If you would like to receive an order confirmation, please enter your email here.",
            },
            terms : {
                text1: "Terms and Conditions of Offer:",
                text2: "Available for individuals prescribed and currently taking Lenvima.",
                text3: "Limit of one kit per person, to be shipped directly to the patient. Not for resale. Eisai Inc. reserves the right to rescind, revoke, or amend this offer without notice at any time. By checking this box, you acknowledge that you have read the Terms and Conditions and qualify for the offer."
            },
            privacy: {
                text1: `Please be advised that the personal information you provide will be used to satisfy your request to receive a welcome kit. The maker of LENVIMA, Eisai Inc. ("Eisai" or "we"), operates this website and our`,
                text2: "Privacy Policy",
                text3: `will govern the collection, use, disclosure and retention of the information you provide here. Please do not submit any personal information unless you have read and agree with the terms of that Privacy Policy. Consistent with the Privacy Policy, we and our service providers may use the personal information you provide to contact you and send you communications, including about products or services that may interest you. At any time, you may request to be removed from the list to receive such communications by following the "Unsubscribe" instructions included in such communication. By clicking "Redeem" below, you agree that you have read, understand, and agree to these conditions.`,
            },
            submit: "Redeem Certificate"
    },
    confirmation: {
        title: "Confirmation",
        text1: "Thank You",
        text2: "Your Submission has been received."
    }
  }