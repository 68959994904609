import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Header from "../components/header";
import popupNew from "../assets/css/images/eisai-popup-new.jpg";
import popupNewEs from "../assets/css/images/eisai-popup-new-es.jpg";
import { useDispatch, useSelector } from "react-redux";
import { redeemCertificate } from "../slices/landingSlice";

const Certificate = ({ }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { certificate, errMessage } = useSelector((state) => state.landing);
  const [error, setError] = useState("")
  const [certificateId, setCertificateId] = useState("");
  const intl = useIntl()
  function submitCode() {
    setError("")
    if (certificateId !== "") {
      dispatch(redeemCertificate(certificateId))
    } else {
      setError("Certificate Code is Required")
    }
  }

  useEffect(() => {
    setError(errMessage)
  }, [errMessage])

  useEffect(() => {
    if (certificate?.status === "success") {
      localStorage.setItem('certificateId', certificateId);
      navigate("/redeem-certificate-form");
    }
  }, [certificate])

  return (
    <>
      <Header isHomepage />
      <div className="container">
        <article className="card2 shadow-1">
          <h1><FormattedMessage id="redeemCertificate.title" /></h1>
          <div className="group">
            <div className="span12">
              <div className="form-field">
                <label htmlFor="certificate-id" className="text-center"><FormattedMessage id="redeemCertificate.subtitle" /><span className="red">*</span></label>
                <input id="certificate-id" autoFocus onChange={(ev) => setCertificateId(ev.target.value)} value={certificateId} className="" placeholder="" type="text" />
                {/* {certificate.status === "fail" ? <div className="error"><FormattedMessage id="redeemCertificate.error" /></div> : null} */}
                {error ? <div className="error">{error}</div> : null}
              </div>
              <button onClick={() => submitCode()} className="btn-primary"><FormattedMessage id="redeemCertificate.submit" /></button>
              <p><FormattedMessage id="redeemCertificate.info1" /> 1-866-613-4724<br />
                (<FormattedMessage id="redeemCertificate.info2" /> EST)</p>
              <div className="popup-img">
                <p><FormattedMessage id="redeemCertificate.needHelp" /> <a href="javascript:void(0)"><FormattedMessage id="redeemCertificate.clickHere" /></a> <img src={intl?.locale === "es" ? popupNewEs : popupNew} /> </p>
              </div>
            </div>
          </div>
        </article>
        <article className="box-content"><FormattedMessage id="redeemCertificate.footer" /> </article>
        <div className="btn-center mtop20">
          <button onClick={() => window.open('https://www.eisaireimbursement.com/-/media/Files/XRay/Lenvima/LENVIMA-Eisai-Assistance-Program-Enrollment-Form.pdf')} className="btn-secondary "><FormattedMessage id="redeemCertificate.clickHere" /></button>
        </div>
      </div>
    </>
  )
}
export default Certificate;