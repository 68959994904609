import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Header from "../components/header";
import { useDispatch } from "react-redux";
import { changeLanguage } from "../slices/landingSlice";

const Landing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goToRedeem = (isSpanish) => {
    if(isSpanish){
      dispatch(changeLanguage())
    }
    navigate("/redeem-certificate");
  }
  useEffect(() => {
    const queryString = new URLSearchParams(window.location.search);
    const token = queryString.get("customToken");
    localStorage.setItem('token', token);
  },[])
  return (
    <>
      <Header isHomepage />
      <div className="container">
        <article className="card shadow-1">
          <div className="group">
            <div className="span12">
              <button
                style={{ maxWidth: "350px" }}
                onClick={() => goToRedeem()}
                className="btn-outline"
              >
                FOR PATIENTS
              </button>
              <div className="spanish">
                <a href="javascript:void(0)" onClick={() => goToRedeem(true)}>(Para Español, Haga Clic Aquí)</a>
              </div>
            </div>
          </div>
        </article>
        <article className="box-content">
          <FormattedMessage id="redeemCertificate.footer" />{" "}
        </article>
        <div className="btn-center mtop20">
          <button onClick={() => window.open('https://www.eisaireimbursement.com/-/media/Files/XRay/Lenvima/LENVIMA-Eisai-Assistance-Program-Enrollment-Form.pdf')} className="btn-secondary ">
            <FormattedMessage id="redeemCertificate.clickHere" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Landing;
