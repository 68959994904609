import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { LOCALES } from "./locales/locales";
import { messages } from "./locales/messages";
import { flattenMessages } from "./locales/utils";

const IntlHoc = ({ children }) => {
  const lang = useSelector((state) =>  state.landing).language  
  return (
    <IntlProvider
      messages={flattenMessages(messages[lang])}
      locale={lang}
      defaultLocale={LOCALES.ENGLISH}
    >
      {children}
    </IntlProvider>
  );
};

export default IntlHoc;
