import React from "react";
import { FormattedMessage } from "react-intl";
import Logo from "../assets/css/images/eisai-logo.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <p>
          <FormattedMessage id="footer.text1" />
        </p>
        <p>
          <FormattedMessage id="footer.text2" />{" "}
          <a
            className="footer-links"
            href="https://www.fda.gov/safety/medwatch-fda-safety-information-and-adverse-event-reporting-program"
            target="_blank"
          >
            www.FDA.gov/medwatch
          </a>
          .
        </p>
        <p>
          <img src={Logo} alt="Eisai Logo" /> <FormattedMessage id="footer.text3" />
        </p>
        <p>
          <FormattedMessage id="footer.text4" />{" "}
        </p>
        <div className="footer-links">
          <a href="https://us.eisai.com/about-eisai-inc" target="_blank">
            <FormattedMessage id="footer.link1" />
          </a>{" "}
          <a href="https://us.eisai.com/privacy-policy" target="_blank">
            <FormattedMessage id="footer.link2" />
          </a>{" "}
          <a href="https://us.eisai.com/legal-notices" target="_blank">
            <FormattedMessage id="footer.link3" />
          </a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
