import React from "react";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import Certificate from "./pages/Certificate";
import PatientForm from "./pages/patientForm";
import ConfirmationScreen from "./pages/Confirmation";

function App() {
  return (
    <BrowserRouter forceRefresh={true}>
      <Routes>
        <Route
          path="/"
          exact
          element={<Landing />}
        />
        <Route
          path="/redeem-certificate"
          exact
          element={<Certificate />}
        />
        <Route
          path="/redeem-certificate-form"
          exact
          element={<PatientForm />}
        />
        <Route
          path="/confirmation"
          exact
          element={<ConfirmationScreen />}
        />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
