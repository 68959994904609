import React from "react";
import { FormattedMessage } from "react-intl";
import Header from "../components/header";
import Footer from "../components/footer";

const ConfirmationScreen = () => {
  return (
    <>
      <div className="main">
        <Header />
        <div className="container">
          <article className="card3 shadow-1">
            <h1>
              <FormattedMessage id="confirmation.title" />
            </h1>
            <div className="confirmation">
              <p style={{ color: "#434a51" }}>
                <strong>
                  <FormattedMessage id="confirmation.text1" />
                </strong>
                <br />
                <FormattedMessage id="confirmation.text2" />
              </p>
            </div>
          </article>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ConfirmationScreen;
