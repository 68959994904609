import { configureStore, applyMiddleware  } from '@reduxjs/toolkit';
import {thunk} from "redux-thunk" 
import landingSlice from './slices/landingSlice'

export const store = configureStore({
  reducer: {
    landing: landingSlice
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
}, applyMiddleware(thunk))