export const spanishText = {
  header: {
    text: "Formulario de Canje del Certificado",
  },
  footer: {
    text1:
      "Este sitio está destinado a los residentes de los Estados Unidos solamente. Los productos aquí tratados pueden tener un etiquetado diferente en distintos países.",
    text2:
      "Para notificar sospechas de reacciones adversas, póngase en contacto con Eisai lnc. al 1-888-274-2378 o con la FDA al 1-800-FDA-1088 o",
    text3:
      " LENVIMA® es una marca registrada utilizada por Eisai Inc. bajo licencia de Eisai R&D Management Co. Ltd.",
    text4:
      "© 2024 Eisai Inc. Todos los derechos reservados. Julio 2024 LENV-US10946",
    link1: "Acerca de Eisai",
    link2: "Politicas de Privacidad",
    link3: "Avisos legales",
  },
  redeemCertificate: {
    title: "Código del Certificado",
    subtitle: "Introduzca el Código del Certificado ",
    error: "Por Favor de Ingresar el Código de Certificado",
    submit: "Enviar",
    info1: "Contacte con Sonexus",
    info2: "Lunes a viernes / de 8 a. m. a8 p. m.",
    clickHere: "Haga Clic Aquí",
    needHelp: "Si necesita Ayuda Encontrando el Código,",
    footer:
      "Para otra manera de redimir, consulte el formulario de inscripción de apoyo al paciente de Lenvima Eisai.",
  },
  redeemForm: {
    title:
      "Ingrese el nombre del consultorio en el que recibió el certificado para el monitor de presión arterial",
    field1: "Nombre del médico",
    field2: "Nombre del consultorio",
    field3: "Ciudad del consultorio",
    field3Error: "Se requiere la ciudad",
    field4: "Estado del consultorio",
    field4Error: "Se requiere el Estado ",
    isRequired: "Se requiere el ",
    selectState: "-- Seleccione el Estado --",
    popup: {
      btn1: "Aceptar",
      btn2: "Cancelar",
      text: "Tenga en cuenta que para canjear el certificado, debe leer los términos y condiciones y verificar que reúne los requisitos. Si no los reúne, cierre la ventana. Comuníquese con Sonexus al 1-866-613-4724 si tiene alguna pregunta o inquietud adicional.",
    },
    shipping: {
      title: "Ingrese los datos del paciente para el envío",
      field1: "Nombre",
      field2: "Apellido",
      field3: "Dirección 1",
      field3Error: "Se requiere la direcciön 1",
      field4: "Dirección 2",
      field5: "Ciudad",
      field5Error: "Se requiere la ciudad",
      field6: "Estado",
      field7: "Código postal",
      field8: "Instrucciones para el envío",
      field9: "Correo electrónico",
      emailHelper:
        "Si desea recibir confirmación del pedido, ingrese su correo electrónico aquí.",
    },
    terms: {
      text1: "Términos y condiciones de la oferta:",
      text2:
        "Disponible para personas a las que se les haya recetado y estén tomando Lenvima. Eisai Inc.",
      text3: `se reserva el derecho de rescindir, revocar o modificar esta oferta sin previo aviso. Al hacer clic en "Enviar", usted reconoce que ha leído los Términos y Condiciones y que puede acogerse a la oferta.`,
    },
    privacy: {
      text1: `Por favor, tenga en cuenta que la información personal que proporcione se utilizará para satisfacer la solicitud de recibir un kit de bienvenida. El fabricante de LENVIMA, Eisai Inc. ("Eisai" o "nosotros"), gestiona este sitio web y nuestra`,
      text2: "política de privacidad",
      text3: `regirá la recogida, el uso, la divulgación y la retención de la información que usted proporcione aquí. Por favor, no envíe ninguna información personal a menos que haya leído y esté de acuerdo con los términos de dicha Política de Privacidad. De acuerdo con la Política de Privacidad, nosotros y nuestros proveedores de servicios podemos utilizar la información personal que usted proporciona para ponernos en contacto con usted y enviarle comunicaciones, incluso sobre productos o servicios que puedan interesarle. En cualquier momento puede solicitar que se le elimine de la lista para recibir dichas comunicaciones siguiendo las instrucciones de "Anulación de la suscripción" incluidas en dicha comunicación. Al hacer clic en "Canjear" a continuación, usted acepta que ha leído, comprendido y aceptado estas condiciones.`,
    },
    submit: "Canjear Certificado",
  },
  confirmation: {
    title: "Confirmación",
    text1: "Gracias",
    text2: "Su envío ha sido recibido.",
  },
};
